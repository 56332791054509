import { Text, Grid, Checkbox } from "@mantine/core";

const SeizureCard = ({ data, classes, utils }) => {
  return (
    <Grid.Col>
      <Grid>
        <Grid.Col span={1}></Grid.Col>
        <Grid.Col span={3}>
          <Text size="sm" fw={700} color="#004236">
            Nombre
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text size="sm" fw={700} color="#004236">
            N° Identificación
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text size="sm" fw={700} color="#004236">
            Fecha de vigencia
          </Text>
        </Grid.Col>
        {data?.map((item, index) => (
          <Grid.Col key={index}>
            <Grid>
              <Grid.Col span={1}>
                <Checkbox className={classes.checkBox} readOnly checked m={0} />
              </Grid.Col>
              <Grid.Col span={3}>
                <Text size="sm">{item?.nombreDemandante}</Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text size="sm">{item?.documentoDemandante}</Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text size="sm">
                  {`${utils.parseDateLocaleFormat(
                    item?.iniVigenciaOficio
                  )} / ${utils.parseDateLocaleFormat(
                    item?.finVigenciaOficio
                  )} `}
                </Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        ))}
      </Grid>
    </Grid.Col>
  );
};

export default SeizureCard;
