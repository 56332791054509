import { ActionIcon } from "@mantine/core";
import { FaInfo } from "react-icons/fa";

const InfoIcon = ({ onClick, small = false }) => {
  return (
    <ActionIcon
      data-testid="action-icon"
      onClick={onClick}
      variant="filled"
      color="orange"
      sx={{ borderRadius: "50%" }}
      size={small && 20}
    >
      <FaInfo size={small ? "12px" : "14px"} color="#fff" />
    </ActionIcon>
  );
};

export default InfoIcon;
