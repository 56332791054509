import { Accordion, Grid, Text } from "@mantine/core";

export const ContableReport = ({ data, GlobalCard, utils }) => {
  const validateServiceKey = (key) => {
    switch (key) {
      case "HOTEL":
        return "Hotel";
      case "AVION":
        return "Avión";
      case "ASISMED":
        return "Asistencia Médica";
      case "ALQVEH":
        return "Alquiler Vehículo";
    }
  };

  return data?.map(([name, dataList], index) => (
    <Accordion defaultValue={name} key={index}>
      <Accordion.Item value={name}>
        <Accordion.Control fz="sm" p={0}>
          {validateServiceKey(name)}
        </Accordion.Control>

        <Accordion.Panel>
          {dataList?.map((item, indexItem) => (
            <GlobalCard key={indexItem}>
              <Grid fz="sm">
                <Grid.Col span="auto">
                  <Text fw={700} color="#004236">
                    Código
                  </Text>
                  <Text>{item.codigo}</Text>
                </Grid.Col>
                <Grid.Col span="auto">
                  <Text fw={700} color="#004236">
                    Valor
                  </Text>
                  <Text>{utils.formatter.format(item.valor)}</Text>
                </Grid.Col>
              </Grid>
            </GlobalCard>
          ))}
        </Accordion.Panel>
      </Accordion.Item>
      <Text ta="right">
        {utils.currenciesSum(dataList?.map((item) => item.valor))}
      </Text>
    </Accordion>
  ));
};
