import { Accordion, Grid, Text, Anchor, Flex } from "@mantine/core";

const AccordionService = ({ data, GlobalCard, utils }) => {
  const validateServiceKey = (key) => {
    switch (key) {
      case "HOTEL":
        return "Hotel";
      case "AVION":
        return "Avión";
      case "ASISMED":
        return "Asistencia Médica";
      case "ALQVEH":
        return "Alquiler Vehículo";
    }
  };
  return (
    <Grid.Col p={15}>
      <Accordion defaultValue="hotel">
        <Accordion.Item value="hotel">
          <Accordion.Control fz="sm" p={0}>
            Servicios
          </Accordion.Control>

          <Accordion.Panel>
            {data?.map((service, index) => (
              <GlobalCard key={index}>
                <Grid fz="sm">
                  <Grid.Col xs={3}>
                    <Text fw={700} color="#004236">
                      Nombre
                    </Text>
                    <Text>{validateServiceKey(service?.idApp)}</Text>
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Text fw={700} color="#004236">
                      Valor
                    </Text>
                    <Text>
                      {service?.tipoMoneda === "COP"
                        ? utils.formatter.format(service?.valor)
                        : utils.formatterUSD.format(service?.valor)}
                    </Text>
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Text fw={700} color="#004236">
                      Nit
                    </Text>
                    <Text>{service?.nitProveedor}</Text>
                  </Grid.Col>
                  <Grid.Col xs={3}>
                    <Text fw={700} color="#004236">
                      Factura
                    </Text>
                    <Flex direction="column" gap={8}>
                      {service?.facturas?.map((bill, index) => (
                        <Anchor
                          href={bill?.urlFactura}
                          c="#fd7e14"
                          target="_blank"
                          underline
                          download
                          key={index}
                        >
                          Ver Documento
                        </Anchor>
                      ))}
                    </Flex>
                  </Grid.Col>
                </Grid>
              </GlobalCard>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Grid.Col>
  );
};

export default AccordionService;
