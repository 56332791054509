import { Text, NumberInput, Flex, Button } from "@mantine/core";
import React from "react";

const ModalUnionApprove = ({
  form,
  opened,
  onClose,
  handleApprove,
  item,
  ModalComponent,
  classes,
}) => {
  return (
    <ModalComponent opened={opened} onClose={onClose} title="Alerta">
      <Text ta="center" color="#004236">
        Modifique la cantidad de los servicios de viaje:
      </Text>
      <NumberInput
        {...form.getInputProps("flights")}
        data-autofocus="true"
        min={0}
        classNames={{
          input: classes.input,
          label: classes.labelSecondary,
        }}
        label="Vuelos"
        type="number"
      />
      <NumberInput
        {...form.getInputProps("buses")}
        min={0}
        classNames={{
          input: classes.input,
          label: classes.labelSecondary,
        }}
        label="Buses"
        type="number"
      />
      <Flex gap={16} mt={16}>
        <Button className={classes.buttonOutline} onClick={onClose} fullWidth>
          Cancelar
        </Button>
        <Button
          className={classes.button}
          onClick={() => handleApprove(item)}
          data-testid="onclick-modalUnionApprove"
          fullWidth
        >
          Aceptar
        </Button>
      </Flex>
    </ModalComponent>
  );
};

export default ModalUnionApprove;
