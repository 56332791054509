import {
  GlobalCard,
  useStyles,
  ServiceDetailCard,
  ModalComponent,
  useWindowSize,
} from "@mfe/js-common-ave-uiutils";
import { useForm } from "@mantine/form";
import { LoadingOverlay } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { navigateToUrl } from "single-spa";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";

import {
  formatter,
  currenciesSum,
  fetchTraveLiquidatorByIDData,
  getAccessTokenApi,
  fetchTravelByIDData,
  fetchLegalizationData,
  parseDateLocaleFormat,
  formatterUSD,
  fetchAccountingReportsData,
  fetchExpensesTypeData,
  fetchLegalizationApprove,
  fetchLegalizationReject,
  fetchUserAdditionalInfoByTravelData,
  fetchLegalizationSendApprove,
  filterByBeneficiaryType,
  fetchSeizureInfoData,
  fetchAidTravelExpensesData,
  fetchApproveUnionLegalization,
  fetchPSEVariable,
  fetchUploadBankSupports,
} from "@mfe/ts-common-ave-utilitaries";
import { useEffect, useState } from "react";
import { LegalizationDetailComponent } from "../components/legalizationDetail.component";

export default function Legalization({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <LegalizationDetail organization={organization} />
    </MsalProvider>
  );
}

export const LegalizationDetail = ({ organization }) => {
  const isAuthenticated = useIsAuthenticated();
  const { classes } = useStyles();
  const isMd = useWindowSize("md");
  const { instance, accounts } = useMsal();
  const isHistorical = localStorage?.getItem("isHistorical") === "true";
  const [openedUnion, { open: openUnion, close: closeUnion }] =
    useDisclosure(false);
  const [tokenApi, setTokenApi] = useState("");
  const [data, setData] = useState<any>({
    travelStatus: {},
    travelLiquidatorData: {},
    travelUserSummary: {},
    legalization: {},
    accountingReports: [],
    travelExpensesType: [],
    dataAid: [],
    seizureInfo: [],
    travelExpenses: [],
  });
  const [modalContent, setModalContent] = useState({
    content: "",
    showButtons: false,
    closeOnClickOutside: true,
    onClick: () => {},
  });
  const [fileSupport, setFileSupport] = useState({
    name: "",
    base: "",
  });
  const [opened, { open, close }] = useDisclosure(false);
  const [openedSupports, { open: openSupports, close: closeSupports }] =
    useDisclosure(false);
  const [openedReject, { open: openReject, close: closeReject }] =
    useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const userData = JSON?.parse(localStorage.getItem("userData"));

  const formApproveUnion = useForm({
    initialValues: {
      flights: 0,
      buses: 0,
    },
  });

  const formRejectTravel = useForm({
    validateInputOnChange: true,
    initialValues: {
      obsAnulacion: "",
    },
    validate: {
      obsAnulacion: (value) =>
        value.length > 2
          ? null
          : "Ingrese el motivo, con al menos 3 caracteres, del rechazo",
    },
  });

  const form = useForm({
    initialValues: {
      reference: "",
    },
  });

  const { Id, TipoViajeAlias, TipoProceso } = data?.travelLiquidatorData || {};

  const isPreApproverTravel =
    data?.travelLiquidatorData?.CodigoEmpleadoaprobadorPrevio ===
    userData?.datosUsuario?.codigoEmpleado;

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );
      fetchUserAdditionalInfoByTravelData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID")
      );
    }
  }, [tokenApi]);

  useEffect(() => {
    if (tokenApi) {
      fetchTravelByIDData(tokenApi, Id, setData, setLoading);
    }
  }, [data?.travelLiquidatorData?.Id]);

  useEffect(() => {
    if (tokenApi) {
      fetchLegalizationData(
        setData,
        tokenApi,
        data?.travelStatus?.liquidadorIdLiquidacion,
        setLoading
      );
    }
  }, [data?.travelStatus?.id]);

  const getAllBpo =
    data?.legalization?.data?.liquidacion?.ViajeTrayecto?.flatMap(
      (trip) => trip.ServicioBpo
    ) || [];

  const legalizationTravelExpense =
    data?.legalization?.data?.liquidacion?.ViajeGasto;

  useEffect(() => {
    if (getAllBpo?.length > 0) {
      fetchAccountingReportsData(setData, tokenApi, Id);
    }
  }, [getAllBpo?.length]);

  useEffect(() => {
    if (legalizationTravelExpense?.length > 0) {
      if (TipoProceso === 4) {
        fetchAidTravelExpensesData(setData, tokenApi);
      } else {
        fetchExpensesTypeData(setData, tokenApi);
      }
    }
  }, [data?.legalization?.data?.liquidacion?.ViajeTrayecto?.length]);

  useEffect(() => {
    if (data?.dataAid?.length > 0 && TipoProceso === 4) {
      fetchSeizureInfoData(
        setData,
        tokenApi,
        filterByBeneficiaryType(data?.dataAid, "TITULAR", "numeroDocumento"),
        filterByBeneficiaryType(
          data?.dataAid,
          "BENEFICIARIO",
          "numeroDocumento"
        )
      );
    }
  }, [data?.dataAid?.length, TipoProceso]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("isHistorical");
    };
  }, []);

  const allCosts = () => {
    return data?.legalization?.data?.liquidacion?.ViajeTrayecto?.reduce(
      (acc, trip) => {
        trip.Viatico.forEach((cost) => {
          const alias = cost.AliasViatico;

          const existingCost = acc.find((c) => c.alias === alias);

          if (existingCost) {
            existingCost.value += cost.Valor;
            existingCost.details.push({
              originCity: trip.nombreCiudadOrigen,
              destinationCity: trip.nombreCiudadDestino,
              initialDate: trip.FechaInicio,
              finalDate: trip.FechaFin,
            });
          } else {
            acc.push({
              alias,
              value: cost.Valor,
              currency: cost.MonedaTipoId,
              details: [
                {
                  originCity: trip.nombreCiudadOrigen,
                  destinationCity: trip.nombreCiudadDestino,
                  initialDate: trip.FechaInicio,
                  finalDate: trip.FechaFin,
                },
              ],
            });
          }
        });

        return acc;
      },
      []
    );
  };

  const modalText = {
    approveTrip: `La legalización del viaje ${Id} será aprobada.`,
    sendApprove: `La legalización del viaje ${Id} será enviada a aprobar.`,
    successSendApprove: `La legalización del viaje ${Id} se envió a aprobar correctamente.`,
    sendCloseZeroBalance: `La legalización del viaje ${Id} será cerrada con saldo cero`,
    successSendCloseZeroBalance: `La legalización del viaje ${Id} se cerro con saldo cero correctamente.`,
    successApproveRequest: `La legalización se aprobó correctamente`,
    rejectTrip: "Rechazar legalización.",
    rejectTripRequest: `La legalización se rechazó correctamente.`,
  };

  const handleAction = (modalContent, actionFunction) => {
    open();
    setModalContent({
      content: modalContent,
      showButtons: true,
      closeOnClickOutside: true,
      onClick: actionFunction,
    });
  };

  const handleOpenUnion = () => {
    openUnion();
  };

  const handleOpenSupport = () => {
    openSupports();
  };

  const handleApproveTrip = async () => {
    const payload = {
      idViaje: Id,
      idAprobador: isPreApproverTravel
        ? data?.travelStatus?.funcionariosIdAprobadorPrevio
        : data?.travelStatus?.funcionariosIdAprobador,
      nombreAprobador: isPreApproverTravel
        ? data?.travelStatus?.funcionarioNombreAprobadorPrevio
        : data?.travelStatus?.funcionarioNombreAprobador,
      segmentoViaje: 0,
    };
    const unionPayload = {
      idViaje: Id,
      idAprobador: data?.travelStatus?.funcionariosIdAprobador,
      nombreAprobador: data?.travelStatus?.funcionarioNombreAprobador,
      segmentoViaje: 0,
      nuevoNumVuelos: formApproveUnion.values.flights,
      nuevoNumBuses: formApproveUnion.values.buses,
    };
    try {
      setLoading(true);
      TipoViajeAlias === "TVSINDICATOS"
        ? await fetchApproveUnionLegalization(
            tokenApi,
            unionPayload,
            data?.travelStatus?.liquidadorIdLiquidacion
          )
        : await fetchLegalizationApprove(
            tokenApi,
            payload,
            data?.travelStatus?.liquidadorIdLiquidacion
          );
      await fetchTravelByIDData(tokenApi, Id, setData, setLoading);
      setModalContent({
        content: modalText["successApproveRequest"],
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => {},
      });
      closeUnion();
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRejectTrip = async () => {
    const payload = {
      idViaje: Id,
      idUsuarioTransaccion:
        data?.legalization?.data?.liquidacion?.CodigoEmpleadoViajero,
      nombreUsuarioTransaccion:
        data?.legalization?.data?.liquidacion?.NombreEmpleadoViajero,
      funcionariosIdAprobador: data?.travelStatus?.funcionariosIdAprobador,
      funcionariosIdAprobadorPrevio: data?.travelStatus
        ?.funcionariosIdAprobadorPrevio
        ? data?.travelStatus?.funcionariosIdAprobadorPrevio
        : "",
      segmentoViaje: 0,
    };
    try {
      setLoading(true);
      await fetchLegalizationReject(
        tokenApi,
        payload,
        data?.travelStatus?.liquidadorIdLiquidacion
      );
      await fetchTravelByIDData(tokenApi, Id, setData, setLoading);
      closeReject();
      setModalContent({
        content: modalText["rejectTripRequest"],
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => {},
      });
      open();
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => close(),
      });
      open();
    } finally {
      setLoading(false);
    }
  };

  const handleSendToAproval = async () => {
    try {
      setLoading(true);
      const payload = {
        idViaje: data?.travelLiquidatorData?.Id,
        idUsuarioTransaccion: data?.travelLiquidatorData?.CodigoEmpleadoViajero,
        nombreUsuarioTransaccion:
          data?.travelLiquidatorData?.NombreEmpleadoViajero,
        segmentoViaje: 0,
      };
      await fetchLegalizationSendApprove(
        tokenApi,
        payload,
        data?.travelStatus?.liquidadorIdLiquidacion
      );
      await fetchTravelByIDData(tokenApi, Id, setData, setLoading);
      setModalContent({
        content: modalText["successSendApprove"],
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => {},
      });
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setModalContent({
          content: JSON?.parse(error?.request?.responseText)?.ExceptionMessage,
          closeOnClickOutside: false,
          showButtons: false,
          onClick: () => {},
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitSupports = async () => {
    const payload = {
      IdViaje: Id,
      NumeroReferencia: form.values.reference,
      DocumentoSoporte: fileSupport.base,
      NombreArchivo: fileSupport.name,
      MontoPagado: data?.travelStatus?.totalAveLegCop,
    };
    try {
      setLoading(true);
      const { data } = await fetchUploadBankSupports(tokenApi, payload);
      setModalContent({
        content: data?.message,
        showButtons: false,
        onClick: () => close(),
        closeOnClickOutside: true,
      });
      open();
      await fetchTravelByIDData(tokenApi, Id, setData, setLoading);
    } catch (error) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
        closeOnClickOutside: true,
      });
      open();
    } finally {
      setLoading(false);
      closeSupports();
    }
  };

  const handlePSE = async () => {
    try {
      setLoading(true);
      const { data } = await fetchPSEVariable(tokenApi);
      const url = data?.data?.replace(
        "{{document}}",
        data?.travelStatus?.beneficiarios?.find(
          (item) => item?.tipo === "VIAJERO"
        )?.numeroDocumento
      );
      window.open(url, "_blank");
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        closeOnClickOutside: true,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAttachSupport = (value) => {
    const reader = new FileReader();
    reader.readAsDataURL(value);
    reader.onload = () => {
      const base = reader.result as string;
      setFileSupport({
        name: value.name,
        base: base.split(",")[1],
      });
    };
  };

  const gotoLegalization = () => {
    localStorage.setItem("liquidationID", data?.travelLiquidatorData?.Id);
    localStorage.setItem("isEdit", "true");
    navigateToUrl("/legalizacion");
  };

  const gotoReservationDetails = () => {
    localStorage.setItem("liquidationID", data?.travelLiquidatorData?.Id);
    navigateToUrl("/reserva-detalles");
  };

  const handleApproveZeroTripAction = () => {
    handleAction(modalText["sendCloseZeroBalance"], () => handleApproveTrip());
  };

  const handleApproveTripAction = () => {
    handleAction(modalText["sendApprove"], () => handleSendToAproval());
  };

  const handleApproveLegalizationAction = () => {
    TipoViajeAlias === "TVSINDICATOS" &&
    userData?.datosUsuario?.filial !== "CNT"
      ? handleOpenUnion()
      : handleAction(modalText["approveTrip"], () => handleApproveTrip());
  };

  const navigateToHistorical = () => {
    navigateToUrl(
      TipoViajeAlias === "TVAMBULATORIOS" ? "/historico-aux" : "/historico"
    );
    localStorage.removeItem("isHistorical");
  };

  const printWindow = () => window.print();

  const utils = {
    formatter,
    formatterUSD,
    currenciesSum,
    parseDateLocaleFormat,
  };

  const legalizationDetailComponentProps = {
    data,
    form,
    isMd,
    GlobalCard,
    classes,
    utils,
    ModalComponent,
    ServiceDetailCard,
    isHistorical,
    handleApproveZeroTripAction,
    handleApproveTripAction,
    handleRejectTrip,
    handleApproveLegalizationAction,
    userData,
    gotoLegalization,
    isPreApproverTravel,
    handleOpenSupport,
    handlePSE,
    gotoReservationDetails,
    openedUnion,
    closeUnion,
    modalContent,
    opened,
    close,
    openedSupports,
    closeSupports,
    allCosts,
    handleSubmitSupports,
    getAllBpo,
    filterByBeneficiaryType,
    handleApproveTrip,
    formApproveUnion,
    navigateToHistorical,
    printWindow,
    legalizationTravelExpense,
    handleAttachSupport,
    openedReject,
    closeReject,
    formRejectTravel,
    openReject,
  };
  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <LegalizationDetailComponent {...legalizationDetailComponentProps} />
  ) : null;
};
